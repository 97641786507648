import Vue from "vue";
import VuetifyAdmin from "vuetify-admin";
// import jwtAuthProvider from "./auth/jwt";
import "vuetify-admin/src/loader";

import axios from "axios";
// import jwtAuthProvider from "./jwt2.js";
import { simpleRestDataProvider } from "vuetify-admin/src/providers";
import { en, fr } from "vuetify-admin/src/locales";

import router from "@/router";
import routes from "@/router/admin";
import store from "@/store";
import i18n from "@/i18n";
import resources from "@/resources";

/**
 * Load Admin UI components
 */
Vue.use(VuetifyAdmin);

/**
 * Create fetch instance with custom authentication headers
 */
const apiURL = process.env.VUE_APP_API_URL;
console.log("apiURL", apiURL);

const http = axios.create({
  apiURL,
  headers: { "X-Requested-With": "XMLHttpRequest" },
});

function cnxAuthProvider() {
  return {
    checkAuth: async () => {
      const res = await fetch("/api/sso/info");
      const data = await res.json();
      store.state.user = data;
      return data?.email ? { data: true } : false;
    },
    login: () => {
      return new Promise((resolve) => {
        console.log("login not implemented");
        resolve();
      });
    },

    getPermissions: (a) => {
      console.log("getPermissions", a, store.state.user);
      return store.state.user.groups;
    },

    logout: async () => {
      await fetch("/api/sso/logout");
      router.replace("/logout");
    },

    getName: (u) => {
      return u.name;
    },

    getEmail: (u) => {
      return u.email;
    },
  };
}

/**
 * Init admin
 */
export default new VuetifyAdmin({
  router,
  store,
  i18n,
  title: "🔐 Locky Luke",
  routes,
  locales: {
    en,
    fr,
  },
  translations: ["en", "fr"],
  dataProvider: simpleRestDataProvider(http),
  authProvider: cnxAuthProvider(http),
  resources,
  http,
  options: {
    dateFormat: "long",
  },
});
