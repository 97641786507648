<template>
  <va-form :id="id" :item="item">
    <v-row justify="center">
      <v-col sm="4">
        <v-card>
          <v-card-text>
            <va-text-input source="nom"></va-text-input>
            <va-text-input source="description" multiline></va-text-input>
            <va-text-input source="url"></va-text-input>
            <va-text-input source="usager"></va-text-input>
            <va-text-input source="motPasse"></va-text-input>

            <va-select-input
              source="groupes"
              :choices="choix"
              multiple="true"
            ></va-select-input>
            <va-save-button></va-save-button>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </va-form>
</template>

<script>
export default {
  props: ["id", "title", "item"],
  data() {
    return {
      choix: [],
    };
  },
  created() {
    this.chargerConfiguration();
  },
  methods: {
    chargerConfiguration() {
      this.$admin.http.get("api/admin/groupes").then((res) => {
        this.choix = res.data.map((g) => g.nom);
        this.item.groupes.forEach((g) => {
          this.choix.push(g);
        });
      });
    },
  },
};
</script>
